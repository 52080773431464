/* global $, Chat, SWAL, Util, NextAction, Common_SignCadesBES_custom, sidepanel */
/* global window, console*/

function UniButton(element) {
	this.element = element;
	return this;
}


$.fn.unibutton = function () {
	return new UniButton(this);
};

UniButton.prototype.initAll = function () {
	this.ajax_noconfirm();
	this.sidepanel();
	this.fix();
	this.chat();
	this.multiple_btn();
	// this.formPost();
	if (typeof cryptoManager !== 'undefined') { cryptoManager.buttonInit(); }
	if ($.fn.confirmation) {
		// this.bsconfirm();
	}
	return this;
};

UniButton.prototype.bsconfirm = function () {
	$('.confirm', this.element).confirmation({
		rootSelector: '.confirm',
		btnOkLabel: 'Да',
		btnOkIcon: 'fa fa-check',
		btnOkClass: 'green',
		btnCancelLabel: 'Нет',
		btnCancelIcon: 'fa fa-times',
		btnCancelClass: 'red'
	});

	$('.confirm', this.element).on('confirmed.bs.confirmation', function (event) {
		const btn = $(this);
		event.stopPropagation(); event.stopImmediatePropagation();
			$.ajax({
				url: Util.blackbox('/app/dynproc/'),
				processData: true,
				type: 'post',
				dataType: 'json',
				data: Util.serialize(btn.data('post')),
				success: function (data) {
					if (data.status) {
						new NextAction(data.next_action, btn);
					} else {
						SWAL.error(data);
					}
				}
			});
	});

	return this;
};

if ($.fn.modalmanager) {
	$.fn.modalmanager.defaults.spinner =
		'<div class="loading-spinner" style="width: 200px; margin-left: -100px;">' +
		'<div class="progress progress-striped active">' +
		'<div class="progress-bar" style="width: 100%;"></div>' +
		'</div>' +
		'</div>';
}

UniButton.prototype.sidepanel = function (){
	if (sidepanel.p_pages.length === 0) $('.panel_prev').hide();

	// При нажатии кнопки "на весь экран"
	$('.panel_fullscreen', this.element).on('click',() => sidepanel.toggleFullscreen() );

	// При нажатии кнопки "Закрыть"
	$('.panel_close').on('click',(event)=>{
		event.stopPropagation(); event.stopImmediatePropagation(); event.preventDefault();
		sidepanel.toggle();
	});

	// При нажатии кнопки "Назад"
	$('.panel_prev', this.element).on('click',()=>{
		sidepanel.loadPanel(sidepanel.p_pages.pop());
	});

	// При нажатии кнопки "Обновить"
	$('.panel_refresh', this.element).on('click',()=>{
		sidepanel.refresh();
	});

	// Открываем и загружаем окна
	$('.panel_btn', this.element).unbind('click');
	$('.panel_btn', this.element).on('click', function(event) {
		event.stopPropagation(); event.stopImmediatePropagation(); event.preventDefault();
		$(event.currentTarget).closest('table').find('tr').removeClass('selected');
		$(event.currentTarget).closest('table').find('tr').removeClass('hide_selected');
		$(event.currentTarget).closest('tr').addClass('selected');

		const data_to_post = $(this).data('post');
		if (sidepanel.panel.find('.panel_refresh').length){
			const cur_post = sidepanel.panel.find('.panel_refresh').data('post');
			const parsed = JSON.parse(cur_post);
			sidepanel.p_pages.push(parsed);
		}

		// Сохраняем данные для возможности откатываться назад в боковой панели
		if (data_to_post.history_clear) sidepanel.p_pages = [];

		const options = { tableUpdate: false };
		if ( event.currentTarget.classList.contains('add_card') ) {
			options.tableUpdate = true;
		}

		sidepanel.loadPanel(data_to_post, options);

		if ( sidepanel.panel.css('right') === '-' + sidepanel.panel.css('width') ){
			sidepanel.toggle();
		} else if ( $(this).data('last') === true ) {
			sidepanel.toggle();
		}

		$('.panel_btn').data('last', false);
		$(this).data('last', true);
	});

};

UniButton.prototype.fix = function(){

	// Скрыть все родительские дропзоны
	$('.folderContent').find('.dropzone').closest('.folderContent').prev().hide();

	// Ставим класс pull-right для меню дропдауна
	$('.dropdown-toggle.pull-right').on('click', function(){
		$(this)
			.next('.dropdown-menu')
			.addClass('pull-right');
	});

	// TODO: Переложить ф-цию в более логичное место
	// Скрыть аккордион, если все поля с .row-toggle-display имеют .d-none
	// Функция лежит в common.js
	accordVisibility();

	// Отключить колёсико в инпутах
	$(document).on('focus', 'input[type=number]', function (e) {
		$(this).on('wheel', (e) => {
			e.preventDefault();
		});
	});
};

UniButton.prototype.chat = function (){
	$('.chat').each((n, e)=> $(e).data('handler', new Chat(e)));
};

UniButton.prototype.formPost = function (modal) {
	this.element.click(function (event) {
		event.preventDefault();
		event.stopPropagation(); event.stopImmediatePropagation();
		var form = modal.find('.form');
		var params = {};
		for (var i = 0; i < form.serializeArray().length; i++) {
			params[form.serializeArray()[i]['name']] = form.serializeArray()[i]['value'];
		}
		$.extend(params, $(this).data());

		$.ajax({
			url: Util.blackbox('/app/dynproc/'),
			processData: false,
			type: 'post',
			dataType: 'json',
			data: $.param(params),
			success: function (data) {
				if (data.status) {
					// modal.data('modal-caller').find('table').unitable().update(1, undefined, modal.data('modal-caller').data('calldata'));
					SWAL.success(data);
				} else {
					SWAL.error(data);
				}
			}
		});
	});
	return this;
};

UniButton.prototype.updateFolder = function (folder){
	const isSidepanel = folder.closest('#sidepanel').length;
	const path = window.location.pathname;
	let card_did = path.replace(/(.*card.)|(.edit.)/g, "");
	const techMatches = path.match('tech_process');
	if ( Array.isArray(techMatches) && techMatches.length ){
		card_did = path.replace(/.*tech_process\//g, "").replace(/\//g, "");
	}
	const refreshElement = $('.panel_refresh');
	let did;
	if (isSidepanel && refreshElement.length){
		did = JSON.parse(refreshElement[0].getAttribute('data-post')).did;
	}else if (card_did){
		did = card_did;
	}else{
		swal('element and did not found');
	}

	var proto = this;
	var fid = folder.attr('data-fid');
	$.ajax({
		url: Util.blackbox('/app/folder/' + did + '/'),
		type: 'post',
		data: {
			fid: fid
		},
		success: function (data) {
			folder.find('.folderContent').replaceWith(data);
			folder.find('.fileCount').text('(' + folder.find('.file').length + ')');
			proto.element = folder;
			proto.initAll();
			folder.find('.folderContent').plugin();
		}
	});
	return this;
};

UniButton.prototype.ajax_noconfirm = function () {
	var proto = this;
  var status_req = false;

  function getLinkFile (url, system) {
    fetch(url).then((response) => {
        response.json().then(json => {
          if (json.status === "error") {
            SWAL.error("Ошибка при загрузке документа.");
          } else if(json.status === "done") {
              let a = document.createElement("a");
              a.href = Util.blackbox("/table-generator/" + system + "/file/") + json.file;
              document.body.appendChild(a);
              a.click();
              a.remove();
              swal.close();
          } else {
            setTimeout(() => getLinkFile (url, system), 2000);
          }

        })
      })
  }


	if ($('.ajax_btn:not(.confirm)', this.element).length) {
		$('.ajax_btn:not(.confirm)', this.element).on('click', function (event) {
			event.preventDefault();
			event.stopPropagation(); event.stopImmediatePropagation();
			var btn = $(this);
			if ( btn.hasClass('dropdown-option__disabled') ) return;
			if (btn.hasClass('download_zip')) {
				window.location = Util.blackbox('/app/download_zip/') + `?${$.param(btn.data('post'))}`;
			} else if(btn.hasClass("ajax_one_req") ){
				if (!status_req) {
          status_req = true;
          SWAL.wait({
            title: "Началась загрузка.",
            text: "Дождитесь окончания загрузки."
          });
					$.ajax({
						url: Util.blackbox('/app/dynproc/'),
						processData: true,
						type: 'post',
						dataType: 'json',
						data: btn.data('post'),
						success: function (data) {
              status_req = false;
              swal.close();
							if (data.status) {
								if (btn.data('alert')) {
									SWAL.success(data);
								}
								if (btn.hasClass('delete_file')) {
									if (Number(data.status)) {
										proto.updateFolder(btn.closest('.folder'));
									} else {
										SWAL.error(data);
									}
								}
								if (typeof data.next_action !== 'undefined' && data.next_action.length) {
									new NextAction(data.next_action, btn);
								}
							} else {
								SWAL.error(data);
							}
						}
					});
				}
			} else if(btn.hasClass("ajax_excel")) {
        	if (!status_req) {
            status_req = true;
            const system = btn.data('post').system_name;
            $.ajax({
            url: Util.blackbox("/table-generator/" + system + "/push/") + btn.data('post').action,
            processData: true,
            type: 'post',
            dataType: 'json',
            contentType: 'application/json',
            data:  JSON.stringify(btn.data('post')),
            success: function success(data) {
              status_req = false;
              if (btn.hasClass("excel_job")) {
                if (data.job) {
                  SWAL.success(
                    "Создалось задание " + data.job + ".\n Перейдите на страницу \"Мои выгрузки\" для скачивания"
                  );
                } else {
                  SWAL.error("Задание не создалось! Обратитесь в техподдержку");
                }
              } else {
                  const url = Util.blackbox("/table-generator/" + system + "/pop/") + data.job
                  if(data.job) {
                    SWAL.wait({
                      title: "Началась загрузка.",
                      text: "Дождитесь загрузки документа."
                    });
                    getLinkFile(url, system);
                  }
                }
							},
						error: function (XMLHttpRequest, textStatus, errorThrown) {
							status_req = false;
							console.log(errorThrown);
						}
            });
          }
			} else if(btn.hasClass("ajax_excel_builder")) {
				if (!status_req) {
					status_req = true;
					const btnData = btn.data('post');
					$.ajax({
					url: Util.blackbox("/v2/excel-builder/") + `report?name=${btnData.name}`,
					processData: true,
					type: 'post',
					dataType: 'json',
					contentType: 'application/json',
					data:  JSON.stringify(btnData.data),
					success: function success(data) {
						status_req = false;						
						if (data.id) {
							SWAL.success(
								"Создалось задание " + data.id + ".\n Перейдите на страницу \"Мои выгрузки\" для скачивания"
							);
						} else {
							SWAL.error("Задание не создалось! Обратитесь в техподдержку");
						}
					},
					error: function (XMLHttpRequest, textStatus, errorThrown) {
						status_req = false;
						console.log(errorThrown);
					}
					});
				}
			} else if(btn.hasClass("spd_traсking_reg_create_postload_card")) {
				$.ajax({
					url: Util.blackbox('/rosreestr-claim-builder/create_postload_tracking_card/'),
					processData: true,
					type: 'post',
					dataType: 'json',
					data: btn.data('post'),
					success: function (data) {
						if (data.id) {
							window.open(Util.blackbox('/app/gs/card/'+ data.id +'/edit/'));
						} else {
							SWAL.error("Произошла ошибка. Обратитесь в тех. поддержку.");
						}
					},
					error: function (XMLHttpRequest) {
						const data = XMLHttpRequest.responseJSON;
						if(data.detail) {
							SWAL.error(data.detail);
						} else {
							SWAL.error("Произошла ошибка. Обратитесь в тех. поддержку.");
						}
					}
				});
			} else if(btn.hasClass("spd_traсking_reg_confirm_postload_card")) {
				$.ajax({
					url: Util.blackbox('/rosreestr-claim-builder/confirm_the_sending_of_postload_package/'),
					processData: true,
					type: 'post',
					dataType: 'json',
					data: btn.data('post'),
					success: function (data) {
						if (data.notification_text) {
							SWAL.success(data.notification_text);
						} else {
							SWAL.error("Произошла ошибка. Обратитесь в тех. поддержку.");
						}
					},
					error: function (XMLHttpRequest) {
						const data = XMLHttpRequest.responseJSON;
						if(data.detail) {
							SWAL.error(data.detail);
						} else {
							SWAL.error("Произошла ошибка. Обратитесь в тех. поддержку.");
						}
					}
				});
			} else if(btn.hasClass("infoeisugi")) {
				const input = btn.parent().find("input.cardinput.tt-input")[0];
				function reloadPageOrSidepanel() {
					if(sidepanel && sidepanel.visible) {
						sidepanel.refresh();
					} else {
						location.reload();
					}   
				}

				$.ajax({
					url: Util.blackbox('/v1/eisugi'),
					data: {
						cadnum: $(input).val(),
						card_id: btn.data('post').card_id
					},
          success: function success(data) {
						if(data) {
							swal({
								title: data,
								type: "success",
								allowOutsideClick: true,
								showConfirmButton: true,
								showCancelButton: false,
								closeOnConfirm: true,
								closeOnCancel: true,
								confirmButtonText: "Закрыть",
							}, function(){
								reloadPageOrSidepanel();
							})
						} else {
							reloadPageOrSidepanel();
						}						      
          },
          error: function error(XMLHttpRequest) {
						console.log(XMLHttpRequest);
            var data = XMLHttpRequest.responseJSON;
            if (data && data.detail) {
              SWAL.error(data.detail);
            } else {
              SWAL.error("Произошла ошибка. Обратитесь в тех. поддержку.");
            }
          }
				});
			} else if(btn.hasClass("rosreestr-claim-builder")) {
				if (!status_req) {
					status_req = true;
					App.blockUI({
						boxed: true,
						target: this.window,
						message: 'Загрузка...'
					});

					$.ajax({
					url: Util.blackbox("/rosreestr-claim-builder/") + `${btn.data('post').action}/`,
					processData: true,
					type: 'post',
					dataType: 'json',
					data: btn.data('post'),
					success: function success(data) {
						status_req = false;
						App.unblockUI(this.window);
						if (data.notification_text) {
							SWAL.success(
								data.notification_text
							);
						} else {
							SWAL.success(
								"Действие выполнено успешно."
							);
						}
					},
					error: function (XMLHttpRequest, textStatus, errorThrown) {
							const errMessage = XMLHttpRequest.responseJSON
							status_req = false;
							App.unblockUI(this.window);
							console.log(errMessage.detail)
							if (errMessage.detail) {
								SWAL.error(errMessage.detail);
							} else {
								SWAL.error("Произошла ошибка. Обратитесь в тех. поддержку.");
							}
						}
					});
				}
			} else {
				$.ajax({
					url: Util.blackbox('/app/dynproc/'),
					processData: true,
					type: 'post',
					dataType: 'json',
					data: btn.data('post'),
					success: function (data) {
						if (data.status) {
							if (btn.data('alert')) {
								SWAL.success(data);
							}
							if (btn.hasClass('delete_file')) {
								if (Number(data.status)) {
									proto.updateFolder(btn.closest('.folder'));
								} else {
									SWAL.error(data);
								}
							}
							if (typeof data.next_action !== 'undefined' && data.next_action.length) {
								new NextAction(data.next_action, btn);
							}
						} else {
							SWAL.error(data);
						}
					}
				});
			}
		});
	}

	if($('.iframe_filter_map', this.element).length) {
		$('.iframe_filter_map', this.element).on('click', function (event) {
			const param = $(this).data('post');
			if(param.que_map_id) {
				window.parent.postMessage(param.que_map_id, 'https://isogd.mosreg.ru/');
			}
		});
	}
	return this;
};

UniButton.prototype.multiple_btn = function () {
        const element = $('.multiple_btn');
        if (!element || element.length === 0) { return; }
        element.each(function (ind, el) {
            el.addEventListener('click', (e) => {
				event.stopPropagation();
				event.stopImmediatePropagation();
				const inputEl = $(this).closest('.mb-5').find('input');
                $.ajax({
                    url: Util.blackbox('/app/dynproc/'),
                    type: "post",
                    data: Util.serialize({value: inputEl.val()}, $(this).data('post')),
                    success: function (data) {
                        if (typeof data.next_action !== 'undefined' && data.next_action.length) {
							new NextAction(data.next_action, $(this));
						}

                    },
                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                        console.log(errorThrown);
                    }

                })
            });
        });
	return this;
};
